import { useState } from "react";
import CompleteDropdown from "../../shared/Dropdown/CompleteDropdown";
import { Dropdown, Row, Col, Table, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faCircleCheck,
  faBan,
  faRecycle,
} from "@fortawesome/free-solid-svg-icons";
import ImagesPreview from "./Previews/ImagesPreview";

const Timelineitem = ({
  post,
  editAction,
  deleteAction,
  publish,
  setDraftAction,
  creatVariantsAction,
}) => {
  const [statusBarColor, setStatusBarColor] = useState("#038edc");
  const [postFiles, setPostFiles] = useState([]);
  
  const timelineContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px", // Adjust the gap between timeline items as needed
  };

  const timelineItemStyle = {
    display: "flex",
    justifyContent: "space-between", // Ensure content is spaced correctly
    alignItems: "center",
  };

  const timelineDateStyle = {
    textAlign: "left",
    paddingRight: "10px", // Adjust the padding as needed
  };

  const timelineContentStyle = {
    textAlign: "right", // Ensure text is aligned to the left within the content area
    flex: 1,
  };

  const image = {
    borderRadius: "4px",
    height: "40px",
    width: "auto",
  };

  const { t } = useTranslation();

  const Tags = ({ post }) => {
    return (
      <>
        {(() => {
          let tags = [];

          if (post.draft) {
            // Highest priority: Draft status
            tags.push(
              <span
                key="draft"
                className="badge-primary-subtle font-size-12 badge bg-link rounded-pill me-3"
              >
                {t("Backend.Post.State.Draft")}
              </span>
            );
          } else {
            tags.push(
              <span
                key="ready"
                className="badge-warning-subtle font-size-12 badge bg-link rounded-pill me-3"
              >
                {t("Backend.Post.State.Ready")}
              </span>
            );
          }

          if (post.schedules && post.schedules.length > 0) {
            // Track already added statuses to avoid duplicates
            const addedStatuses = new Set();

            for (const schedule of post.schedules) {
              const status = schedule.post_status || "scheduled";

              // Skip if the status has already been added
              if (!addedStatuses.has(status)) {
                const capitalizedStatus =
                  status.charAt(0).toUpperCase() + status.slice(1);

                const badgeClass =
                  status === "published"
                    ? "badge-success-subtle"
                    : status === "scheduled"
                    ? "badge-warning-subtle"
                    : status === "ready"
                    ? "badge-light-subtle"
                    : status === "error"
                    ? "badge-danger-subtle"
                    : "badge-secondary-subtle";

                status === "published" && setStatusBarColor("#51d28c");

                tags.push(
                  <span
                    key={schedule.id}
                    className={`badge me-3 font-size-12 badge bg-link rounded-pill ${badgeClass}`}
                  >
                    {t(`Backend.Post.State.${capitalizedStatus}`)}
                  </span>
                );

                addedStatuses.add(status);
              }
            }
          }

          return tags.length > 0 ? tags : null;
        })()}
      </>
    );
  };

  let postType = post.post_type
    ? "Backend.Post.PostType." + post.post_type
    : "Backend.Post.PostType.Text";
  let contentType = post.content_type
    ? "Backend.Post.ContentType." + post.content_type
    : "Backend.Post.ContentType.Personal";

  return (
    <div className="timeline-item">
      <div className="timeline-block">
        <div
          className="timeline-box card"
          style={{ borderLeft: "2px solid " + statusBarColor }}
        >
          <div className="card-header">
            <h5>
              {post.title}

              <span className="pb-3">
                <CompleteDropdown
                  deleteAction={deleteAction}
                  editAction={editAction}
                >
                  <div className="dropdown-divider" />
                  {!post.draft ? (
                    <Dropdown.Item
                      onClick={() => {
                        setDraftAction();
                      }}
                      className="text-danger"
                    >
                      <FontAwesomeIcon icon={faBan} className="fs-6 me-2" />
                      {t("Backend.Post.SetDraft")}
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      onClick={() => {
                        setDraftAction();
                      }}
                      className="text-success"
                    >
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="fs-6 me-2"
                      />
                      {t("Backend.Post.SetReady")}
                    </Dropdown.Item>
                  )}
                  {!post.draft && (
                    <>
                      <div className="dropdown-divider" />
                      <Dropdown.Item
                        onClick={() => {
                          publish(post.id);
                        }}
                        className="text"
                      >
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          className="fs-6 me-2"
                        />
                        {t("Backend.Post.Publish")}
                      </Dropdown.Item>
                    </>
                  )}
                  <div className="dropdown-divider" />
                  <Dropdown.Item
                    onClick={() => {
                      creatVariantsAction();
                    }}
                  >
                    <FontAwesomeIcon icon={faRecycle} className="fs-6 me-2" />
                    {t("Backend.Post.Variants.Create")}
                  </Dropdown.Item>
                </CompleteDropdown>
              </span>
            </h5>
          </div>
          <div className="card-body">
            <Row>
              <Col md={8}>
                <div style={timelineContainerStyle}>
                  <div style={timelineItemStyle}>
                    <div style={timelineContentStyle}></div>
                  </div>
                </div>
                <div className="text-muted">
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html:
                        post.text &&
                        (post.text.length > 400
                          ? post.text.slice(0, 400).replace(/\n/g, "<br />") +
                            "..."
                          : post.text.replace(/\n/g, "<br />")),
                    }}
                  />
                </div>
                <ImagesPreview postFiles={postFiles}/>
              </Col>
              <Col>
                <Card>
                  <Card.Header>{t("Backend.Post.Types")}</Card.Header>
                  <Card.Body>
                    <span
                      className={`badge me-3 font-size-12 badge bg-link rounded-pill badge-info-subtle`}
                    >
                      {t(contentType)}
                    </span>
                    <span
                      className={`badge me-3 font-size-12 badge bg-link rounded-pill badge-success-subtle`}
                    >
                      {t(postType)}
                    </span>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header> {t("Backend.Post.Schedule.Plan")}</Card.Header>
                  <Card.Body>
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>{t("Backend.Post.Schedule.Date")}</th>
                          <th>{t("Backend.Post.Schedule.Platform")}</th>
                          <th>{t("Backend.Post.Schedule.Status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {post.schedules &&
                          post.schedules.slice(0, 3).map((schedule, index) => (
                            <tr key={index}>
                              <td>{schedule.scheduled_for}</td>
                              <td>{schedule.platform}</td>
                              <td>{schedule.post_status}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="card-footer">
            <Tags post={post} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timelineitem;
