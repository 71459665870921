import { Row, Col } from "react-bootstrap";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import listHandler from "@serie3/common/Domain/listHandler";
import PostFormView from "./components/PostFormView";
import AddButton from "../shared/Buttons/AddButton";
import ToastMessages from "../../ToastMessages";
import { useTranslation } from "react-i18next";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LoadingModal from "../shared/Modals/LoadingModal";
import VariantsModal from "./components/VariantsModal";
import TimnelineTab from "./components/TimnelineTab";
import NewButtonWithIcon from "../shared/Buttons/NewButtonWithIcon";
import PostAssistant from "./components/PostAssistant";

const Timeline = () => {
  const [posts, setPosts] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [planned, setPlanned] = useState([]);
  const [unscheduled, setUnscheduled] = useState([]);
  const [published, setPublished] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editPost, setEditPost] = useState();
  const [trigger, setTrigger] = useState(-1);
  const [showVariants, setShowVariants] = useState(false);
  const [showPosting, setShowPosting] = useState(false);
  const [showGenerating, setShowGenerating] = useState(false);
  const [showPostAssistant, setShowPostAssistant] = useState(false);

  const { t } = useTranslation();

  const sortPosts = (posts) => {
    const drafts = [];
    const unscheduled = [];

    posts.forEach((post) => {
      if (post.draft === true) {
        drafts.push(post);
      }

      if (
        Array.isArray(post.schedules) &&
        post.schedules.length === 0 &&
        post.draft === false
      ) {
        unscheduled.push(post);
      }

      if (Array.isArray(post.schedules) && post.schedules.length > 0) {
        let isPublished = false;
        let isPlanned = false;

        post.schedules.forEach((schedule) => {
          if (schedule.post_status === "published") {
            isPublished = true;
          } else {
            isPlanned = true;
          }
        });
      }
    });

    setDrafts(drafts);
    setUnscheduled(unscheduled);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.index,
        sortPosts,
        SocialEndpoints.posts.dataField
      );
    };

    const fetchPlannedPosts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.plannedOnly,
        setPlanned,
        SocialEndpoints.posts.scheduledDataField
      );
    };

    const fetchPulishedPosts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.postedOnly,
        setPublished,
        SocialEndpoints.posts.scheduledDataField
      );
    };


    fetchPulishedPosts();
    fetchPlannedPosts();
    fetchPosts();
  }, [trigger]);

  const editAction = (post) => {
    setEditPost(post);
    setShowEdit(true);
  };

  const postErrorFunction = () => {
    let message = t("Backend.Post.ErrorPublishing");
    ToastMessages.Error(message);
  };

  const AddBtn = () => {
    return (
      <>
        <NewButtonWithIcon
          show={showPostAssistant}
          setShow={setShowPostAssistant}
          handleClick={() => setShowPostAssistant(1)}
          title={t("Backend.Post.Assistant.Startbutton")}
        />
        <AddButton
          addFunction={async () => {
            let response = await crudApi.createNew(
              SocialEndpoints.posts.new,
              {}
            );
            if (response.ok) {
              listHandler.newToList(response, posts, setPosts, null, true);
              setEditPost(response.data);
              setShowEdit(true);
            }
          }}
        />
      </>
    );
  };

  const deleteAction = (updateListFunction, dataList, index, postId) => {
    const deleteUrl = SocialEndpoints.posts.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, postId),
      updateListFunction,
      dataList
    );
  };

  const publish = async (postId) => {
    setShowPosting(true);
    const response = await crudApi.justGet(
      SocialEndpoints.posts.publish.linkedin + postId
    );
    let data = JSON.stringify(response.data);
    listHandler.updateList(
      response,
      data,
      posts,
      setPosts,
      postErrorFunction,
      true
    );
    if (response.ok) {
      let message = t("Backend.Post.SuccessfulPublished");
      ToastMessages.SuccessfulSave(message);
    } else {
      let message = t("Backend.Post.ErrorPublishing");
      ToastMessages.SuccessfulSave(message);
    }
    setShowPosting(false);
  };

  const setDraft = async (postId) => {
    const response = await crudApi.justGet(
      SocialEndpoints.posts.setDraft + postId
    );
    let data = JSON.stringify(response.data);
    listHandler.updateList(
      response,
      data,
      posts,
      setPosts,
      postErrorFunction,
      true
    );
    if (response.ok) {
      let message = t("Backend.Post.SuccessfulPublished");
      ToastMessages.SuccessfulSave(message);
    }
  };

  return (
    <>
      <PostAssistant
        show={showPostAssistant}
        setShow={setShowPostAssistant}
        setShowEdit={setShowEdit}
        posts={drafts}
        setPosts={setDrafts}
        setEditPost={setEditPost}
        showSpinner={showGenerating}
        setShowSpinner={setShowGenerating}
      />
      <VariantsModal
        show={showVariants}
        setShow={setShowVariants}
        post={editPost}
        dataList={drafts}
        setDataList={setDrafts}
      />
      <LoadingModal
        showSpinner={showPosting}
        setShowSpinner={setShowPosting}
        text={t("Backend.Post.Publishing")}
      />
      <LoadingModal
        showSpinner={showGenerating}
        setShowSpinner={setShowGenerating}
        text={t("Backend.Post.Assistant.GeneratingPost")}
      />
      {editPost && (
        <PostFormView
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          dataList={drafts}
          setDataList={setDrafts}
          editData={editPost}
          setEditData={setEditPost}
          setTrigger={setTrigger}
          trigger={trigger}
        />
      )}
      <Tabs
        defaultActiveKey="drafts"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="drafts" title={t("Backend.Post.Tabs.Drafts")}>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Timeline</h4>
                <AddBtn />
              </div>
            </Col>
          </Row>
          <div className="verti-timeline left-timeline">
            <TimnelineTab
              posts={drafts}
              editAction={editAction}
              deleteAction={deleteAction}
              setDraft={setDraft}
              setEditPost={setEditPost}
              setShowVariants={setShowVariants}
              publish={publish}
              setPosts={setDrafts}
            />
          </div>
        </Tab>
        <Tab
          eventKey="unschedule"
          title={t("Backend.Post.Tabs.ReadyUnschedule")}
        >
          <div className="verti-timeline left-timeline">
            <TimnelineTab
              posts={unscheduled}
              editAction={editAction}
              deleteAction={deleteAction}
              setDraft={setDraft}
              setEditPost={setEditPost}
              setShowVariants={setShowVariants}
              publish={publish}
              setPosts={setUnscheduled}
            />
          </div>
        </Tab>
        <Tab eventKey="planned" title={t("Backend.Post.Tabs.Ready")}>
          <div className="verti-timeline left-timeline">
            <TimnelineTab
              posts={planned}
              editAction={editAction}
              deleteAction={deleteAction}
              setDraft={setDraft}
              setEditPost={setEditPost}
              setShowVariants={setShowVariants}
              publish={publish}
              setPosts={setPlanned}
              useSchedule={true}
            />
          </div>
        </Tab>
        <Tab eventKey="published" title={t("Backend.Post.Tabs.Published")}>
          <div className="verti-timeline left-timeline">
            <TimnelineTab
              posts={published}
              editAction={editAction}
              deleteAction={deleteAction}
              setDraft={setDraft}
              setEditPost={setEditPost}
              setShowVariants={setShowVariants}
              publish={publish}
              setPosts={setPublished}
              useSchedule={true}
            />
          </div>
        </Tab>
      </Tabs>
    </>
  );
};

export default Timeline;
