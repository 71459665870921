import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import crudApi from "@serie3/common/api/crudApi";
import DocumentsEndpoints from "@serie3/mymediaplanner/Modules/Documents/endpoints";


const PicturePreview = ({ file }) => {
  const { t } = useTranslation();
  const [picture, setPicture] = useState();

  useEffect(() => {
    const getImage = async () => {
      const response = await crudApi.getBinary(
        DocumentsEndpoints.show + file.document.id
      );
      if (response.status === 200) {
        const url = window.URL.createObjectURL(response.data);
        setPicture(url);
      }
    };

    if (file.document && file.document.id) {
      getImage();
    }
  }, [file]);

  return picture && (
    <img
      src={picture}
      alt="postimage"
      style={{
        borderRadius: "1.6rem",
        maxWidth: "514px",
      }}
    />
  );
};

export default PicturePreview;
