import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import PicturePreview from "./PicturePreview";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ImagesPreview = ({ postFiles }) => {
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return postFiles && postFiles.length == 1 ? (
    <PicturePreview file={postFiles[0]} />
  ) : (
    <Row>
      <Carousel autoPlay={false} infinite={true} responsive={responsive}>
        {postFiles.map((file, index) => {
          return <PicturePreview file={file} />;
        })}
      </Carousel>
    </Row>
  );
};

export default ImagesPreview;
