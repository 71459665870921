import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import crudApi from "@serie3/common/api/crudApi";
import CustomEvent from "./components/CustomEvent";
import NewModal from "../shared/Modals/NewModal";
import LinkedinPreview from "./components/Previews/LinkedinPreview";
import { Card } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentsEndpoints from "@serie3/mymediaplanner/Modules/Documents/endpoints";

// Setup moment for date localization
const localizer = momentLocalizer(moment);

const PostCalendar = () => {
  const [events, setEvents] = useState([]);
  const [viewPost, setViewPost] = useState(null);
  const [viewPostId, setViewPostId] = useState(null);
  const [showPreviewPost, setShowPreviewPost] = useState(false);
  const [postFiles, setPostFiles] = useState([]);

  const setCalendarEntry = (schedules) => {
    const calendarEntry = [];
    schedules.map((schedule, index) => {
      let scheduleDate = schedule.scheduled_for;

      if (typeof scheduleDate === "undefined") {
        scheduleDate = schedule.posted_at;
      }

      const startDate = new Date(scheduleDate);
      const types = [];

      types.push(schedule.post.post_type);
      types.push(schedule.post.content_type);

      let status = schedule.post_status;
      if (typeof status === "undefined") {
        status = "planned";
      }

      const entry = {
        id: schedule.post.id,
        title: schedule.post.title,
        start: new Date(startDate),
        end: new Date(startDate.getTime() + 60 * 60 * 1000),
        status: status,
        types: types,
      };
      calendarEntry.push(entry);
    });
    setEvents(calendarEntry);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.schedule.index,
        setCalendarEntry,
        SocialEndpoints.posts.scheduledDataField
      );
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    const fetchViewPost = async () => {
      const data = await crudApi.show(SocialEndpoints.posts.show, viewPostId);
      if (data) {
        setViewPost(data);
      }
    };

    const loadPostDocuments = async () => {
      const response = await crudApi.getAll(
        DocumentsEndpoints.showPostFiles + viewPostId
      );
      if (response.ok) {
        setPostFiles(response.data);
      }
    };

    if (viewPostId != null) {
      fetchViewPost();
      loadPostDocuments();
    }
  }, [viewPostId]);

  const eventStyleGetter = (event) => {
    let borderColor = "#3174ad"; // Default color

    // Check if event has certain types
    if (event.status.includes("error")) {
      borderColor = "#f56c6c"; // Red for meetings
    }
    if (event.status.includes("scheduled")) {
      borderColor = "#e6a23c"; // Orange for important events
    }
    if (event.status.includes("published")) {
      borderColor = "#67c23a"; // Green for reviews
    }

    const style = {
      borderLeft: `10px solid ${borderColor}`,
      backgroundColor: "#fff",
      borderRadius: "5px",
      color: "black",
      border: "1px solid #ddd",
      display: "block",
      paddingLeft: "0",
    };

    return {
      style,
    };
  };

  const handleSelectSlot = (slotInfo) => {
    // slotInfo.start: Start date/time of the selection
    // slotInfo.end: End date/time of the selection
    // slotInfo.slots: Array of dates representing the selected slots
    // slotInfo.action: 'select' or 'click'

    // Example action: Open a modal or prompt
    console.log("Selected slot:", slotInfo);
   
  };

  return (
    <>
      <NewModal show={showPreviewPost} setShow={setShowPreviewPost} onHide={() => setViewPost(null)}>
        <Card>
          <Card.Body>
            <Tabs
              defaultActiveKey="linkedIn"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="linkedIn"
                title={<FontAwesomeIcon icon={faLinkedin} size="2x" />}
                style={{
                  backgroundColor: "#f4f2ee",
                  paddingBottom: "15px",
                  paddingTop: "15px",
                }}
              >
                {viewPost && (
                  <LinkedinPreview text={viewPost.text} postFiles={postFiles} />
                )}
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </NewModal>
      <div style={{ height: 500 }}>
        <Calendar
          selectable
          onSelectSlot={handleSelectSlot}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          eventPropGetter={eventStyleGetter}
          components={{
            event: (props) => (
              <CustomEvent
                {...props}
                setViewId={(id) => {
                  setShowPreviewPost(true);
                  setViewPostId(id);
                }}
              />
            ),
          }}
        />
      </div>
    </>
  );
};

export default PostCalendar;
