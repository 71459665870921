import { Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NewModal from "../../shared/Modals/NewModal";
import { useState } from "react";
import NewButtonWithIcon from "../../shared/Buttons/NewButtonWithIcon";
import crudApi from "@serie3/common/api/crudApi";
import { useFormik } from "formik";
import ScheduleForm from "@serie3/mymediaplanner/Modules/Social/Forms/ScheduleForm";
import Schedule from "@serie3/mymediaplanner/Modules/Social/Entity/Schedule";
import DateTimeField from "../../Form/DateTimeField";
import SelectField from "../../Form/SelectField";
import SaveButton from "../../shared/Buttons/SaveButton";
import { faTrash, faPen, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import listHandler from "@serie3/common/Domain/listHandler";
import SocialEndpoints from "@serie3/mymediaplanner/Modules/Social/endpoints";
import TextField from "../../Form/TextField";
import Timezones from "../../Form/Timezones";
import PostCalendar from "../PostCalendar";
import { formatDate } from "@serie3/common/Shared/timeingStuff";
import { useEffect } from "react";


const SchedulePostTab = ({ post }) => {
  const { t } = useTranslation();
  const [showPublished, setShowPublished] = useState(false);
  const [showPlanningForm, setshowPlanningForm] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [editData, setEditData] = useState();

  const scheduleForm = ScheduleForm.createScheduleForm(
    dataList,
    setDataList,
    setshowPlanningForm,
    post.id
  );
  const formik = useFormik(scheduleForm);

  const addFunction = () => {
    setEditData(null);
    setshowPlanningForm(true);
  };

  const setPublishedFunction = () => {
    setEditData(null);
    setShowPublished(true);
  };

  const Save = () => {
    return <SaveButton formik={formik} />;
  };

  const deleteSchedulue = (index, scheduleId) => {
    listHandler.deleteFromList(
      index,
      crudApi.del(SocialEndpoints.posts.schedule.delete + scheduleId),
      setDataList,
      dataList
    );
  };

  useEffect(() => {
    const fetchSchedules = async () => {
      await crudApi.getAll(
        SocialEndpoints.posts.schedule.indexAllForPost + post.id,
        setDataList,
        SocialEndpoints.posts.scheduledDataField
      );
    }

    fetchSchedules();

  },[post.id]);

  return (
    <>
      <NewModal
        title={t("Backend.Post.Schedule.ModalTitle")}
        show={showPlanningForm}
        setShow={setshowPlanningForm}
        onExit={() => {
          formik.resetForm({ values: { Schedule } });
        }}
        onShow={() => {
          editData
            ? formik.setValues(ScheduleForm.setEditData(editData))
            : formik.setValues(Schedule);
        }}
        ExtraButton={Save}
      >
        <Row>
          <Col>
            <DateTimeField
              formik={formik}
              label={t("Backend.Post.Schedule.ScheduleFor")}
              valueName={"local_user_time"}
            />
          </Col>
          <Col>
            <Timezones formik={formik} />
          </Col>
          <Col>
            <SelectField
              formik={formik}
              valueName={"platform"}
              label={t("Backend.Post.Schedule.Platformname")}
            >
              <option value={"linkedin"}>LinkedIn</option>
            </SelectField>
          </Col>
        </Row>
      </NewModal>
      <NewModal
        title={t("Backend.Post.SetPublished")}
        show={showPublished}
        setShow={setShowPublished}
        onExit={() => {
          formik.resetForm({ values: { Schedule } });
        }}
        onShow={() => {
          editData
            ? formik.setValues(ScheduleForm.setEditData(editData))
            : formik.setValues(Schedule);
        }}
        ExtraButton={Save}
      >
        <Row className="mt-5">
          <Col>
            <DateTimeField
              formik={formik}
              label={t("Backend.Post.Schedule.PostedAt")}
              valueName={"posted_at"}
            />
          </Col>
          <Col>
            <Timezones formik={formik} />
          </Col>
          <Col>
            <TextField
              formik={formik}
              label={t("Backend.Post.PortalId")}
              valueName={"portal_post_id"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectField
              formik={formik}
              valueName={"platform"}
              label={t("Backend.Post.Schedule.Platformname")}
            >
              <option value={"linkedin"}>LinkedIn</option>
            </SelectField>
          </Col>
        </Row>
      </NewModal>
      <Row>
        <Col>
          <h3>{t("Backend.Post.Schedule.ScheduleTitle")}</h3>
          <Row className="mb-3">
            <Col className="d-flex justify-content-end">
              <span className="me-3">
                <NewButtonWithIcon
                  handleClick={setPublishedFunction}
                  title={t("Backend.Post.Schedule.SetPublished")}
                />
              </span>
              <NewButtonWithIcon
                handleClick={addFunction}
                title={t("Backend.Post.Schedule.Plan")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>{t("Backend.Post.Schedule.Date")}</th>
                    <th>{t("Translation.Timezone")}</th>
                    <th>{t("Backend.Post.Schedule.Platform")}</th>
                    <th>{t("Backend.Post.Schedule.Status")}</th>
                    <th colSpan={2}></th>
                  </tr>
                </thead>
                <tbody>
                  {dataList &&
                    dataList.map((schedule, index) => (
                      <tr>
                        <td>{formatDate(schedule.local_user_time)}</td>
                        <td>{schedule.timezone}</td>
                        <td>{schedule.platform}</td>
                        <td>{schedule.post_status}</td>
                        {schedule.portal_post_id && (
                          <td style={{ color: "#51d28c" }}>
                            <a
                              href={`https://www.linkedin.com/feed/update/${schedule.portal_post_id}`}
                              target="_blank"
                            >
                              <FontAwesomeIcon
                                icon={faLink}
                                className="ms-2 me-2"
                              />
                            </a>
                          </td>
                        )}
                        <td style={{ color: "#51d28c" }}>
                          <FontAwesomeIcon
                            icon={faPen}
                            className="ms-2 me-2"
                            onClick={() => {
                              setEditData(schedule);
                              setshowPlanningForm(true);
                            }}
                          />
                        </td>
                        <td style={{ color: "#f34e4e" }}>
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="ms-2 me-2"
                            onClick={() => {
                              deleteSchedulue(index, schedule.id);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
        <Col>
          <h3>{t("Backend.Post.Schedule.CalendarTitle")}</h3>
          <PostCalendar />
        </Col>
      </Row>
    </>
  );
};

export default SchedulePostTab;
