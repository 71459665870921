const CustomEvent = ({ event, setViewId }) => {
    let borderColor = "#3174ad"; // Default color

    // Check if event has certain types
    if (event.status.includes("error")) {
      borderColor = "#f56c6c"; // Red for meetings
    }
    if (event.status.includes("scheduled")) {
      borderColor = "#e6a23c"; // Orange for important events
    }
    if (event.status.includes("published")) {
      borderColor = "#67c23a"; // Green for reviews
    }

    console.log(event.id);
  return (
    <div style={{ borderRadius: "5px", position: "relative", borderLeft: `10px solid ${borderColor}`, }} onClick={() => {setViewId(event.id)}}>
      {/* Left border to indicate status */}
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          width: "6px",
          backgroundColor: event.statusColor, // Color based on status
        }}
      ></div>

      {/* Event content */}
      <div style={{ marginLeft: "10px" }}>
        <div style={{ fontWeight: "bold" }}>{event.title}</div>
        <div style={{ fontSize: "0.8em", color: "#888" }}>
                {event.status}
                <br />
                {event.types && event.types[0]}: {event.types && event.types[1]}
        </div>
      </div>
    </div>
  );
};

export default CustomEvent;
