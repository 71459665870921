import { useState, useEffect, useRef } from "react";

const Teleprompter = ({ text, speed = 80, initialMarginTop = 0 }) => {
  const [isScrolling, setIsScrolling] = useState(false); // Initially not scrolling
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);

  // Toggle scroll on/off
  const toggleScroll = () => {
    setIsScrolling(!isScrolling);
  };

  // Start the scrolling
  const startScroll = () => {
    setIsScrolling(true);
  };

  // Restart scroll
  const restartScroll = () => {
    setScrollPosition(0);
    setIsScrolling(false); // Optionally start scrolling immediately after restart
  };

  // Start scrolling the text
  useEffect(() => {
    if (isScrolling) {
      const interval = setInterval(() => {
        setScrollPosition((prev) => prev + 1);
      }, speed);

      return () => clearInterval(interval);
    }
  }, [isScrolling, speed]);

  // Update scroll position during the scroll effect
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition]);

  return (
    <div className="teleprompter-container">
      <div
        className="teleprompter-text"
        ref={scrollRef}
        style={{ marginTop: `${initialMarginTop}px` }}
      >
        <p>
          <h2>Press Start</h2>
          Hier gehts los
        </p>

        {text && text.split("\n").map((line, index) => (
          <>
            <p key={index} style={{marginBottom: "75px"}}>{line}</p>
          </>
        ))}
      </div>
      <div className="teleprompter-controls">
        {!isScrolling && (
          <button className="start-btn" onClick={startScroll}>
            Start
          </button>
        )}
        {isScrolling && (
          <button onClick={toggleScroll}>
            {isScrolling ? "Pause" : "Play"}
          </button>
        )}
        <button onClick={restartScroll}>Restart</button>
      </div>
    </div>
  );
};

export default Teleprompter;
