import { Row, Col, Card, CardBody } from "react-bootstrap";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import DashboardEndpoints from "@serie3/mymediaplanner/Modules/Dashboard/endpoints";
import { useTranslation } from "react-i18next";
import { formatDate } from "@serie3/common/Shared/timeingStuff";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const getDashboardData = async () => {
      let response = await crudApi.justGet(DashboardEndpoints.overview);
      if (response.ok) {
        setDashboardData(response.data);
      }
    };

    getDashboardData();
  }, []);
  return dashboardData ? (
    <>
      <Row>
        <Col md={4}>
          <Card>
            <CardBody>
              <h6 className="font-size-xs text-uppercase">{t("Backend.Dashboard.TotalPosts")}</h6>
              <h4 className="mt-4 font-weight-bold mb-2 d-flex align-items-center">
                {dashboardData.posts.length}
              </h4>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardBody>
              <h6 className="font-size-xs text-uppercase">{t("Backend.Dashboard.Schedules")}</h6>
              <h4 className="mt-4 font-weight-bold mb-2 d-flex align-items-center">
                {dashboardData.schedules.length}
              </h4>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardBody>
              <h6 className="font-size-xs text-uppercase">{t("Backend.Dashboard.Errors")}</h6>
              <h4 className="mt-4 font-weight-bold mb-2 d-flex align-items-center">
                {dashboardData.error.length}
              </h4>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {dashboardData.tokens &&
          dashboardData.tokens.localToken &&
          dashboardData.tokens.localToken.length > 0 &&
          dashboardData.tokens.localToken.map((tokendata, index) => {
            return (
              <Col md={4}>
                <Card>
                  <CardBody>
                    <h6 className="font-size-xs text-uppercase">
                      {tokendata.plattform_name} {t("Backend.Dashboard.TokenExpires")}
                    </h6>
                    <h4 className="mt-4 font-weight-bold mb-2 d-flex align-items-center">
                      {formatDate(tokendata.expires)}
                    </h4>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
      </Row>
    </>
  ) : (
    <Row>
      <Col>Dashboard can not be loaded</Col>
    </Row>
  );
};

export default Dashboard;
